<template>
  <router-link :to="model.path" style="text-decoration: none;">
    <q-card
      class="clickable bg-white text-dark fit"
      @click="handleRoute(model.path)"
    >
      <q-card-section class="border-bottom q-px-none q-py-xs">
        <q-legend
          :label="$t(model.name)"
          :icon="model.icon"
        />
      </q-card-section>

      <q-card-section v-if="model.description" class="text-caption">
        {{ $t(model.description) }}
      </q-card-section>

      <q-card-section v-if="model.details && model.details.length > 0">
        <ul class="q-ma-none">
          <li v-for="(text, i) in model.details" :key="i">
            {{ text }}
          </li>
        </ul>
      </q-card-section>
    </q-card>
  </router-link>
</template>

<script>
export default {
  name: 'RouteCard',
  props: {
    model: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    handleRoute (path) {
      this.$router.push(path)
    }
  }
}
</script>
