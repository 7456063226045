<template>
  <div
    :class="`q-pa-md ${$q.dark.isActive ? '' : 'bg-grey-2'}`"
    style="min-height: calc(100vh - 50px)"
  >
    <div class="row">
      <div
        v-for="option in options"
        :key="option.path"
        class="col-12 col-md-4 col-sm-6 q-pa-md"
      >
        <route-card :model="option" />
      </div>
    </div>
  </div>
</template>

<script>
// Components
import RouteCard from '../components/global/RouteCard.vue'

export default {
  name: 'Settings',
  components: {
    RouteCard
  },
  data () {
    return {
      options: this.$utils.router.getRouteByEntity('Settings').children
    }
  }
}
</script>
